'use client';

// React components
import Header from './component/AppHeader/Header';
import Image from 'next/image';

// Mui components
import { Container, Box, Typography, Stack } from '@mui/material';

// Translate
import { useTranslations } from 'next-intl';

// Component
export default function NotFound() {
  // * Translate
  const t = useTranslations('NotFoundPage');

  return (
    <Box component={'div'} height={'100vh'}>
      <Header />
      <Box
        component={'div'}
        sx={{
          minHeight: '100%',
          display: 'flex',
        }}
      >
        <Container maxWidth="xl">
          <Stack
            component={'div'}
            direction="column"
            gap={{ xs: 5, md: 8 }}
            minHeight={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
          >
            <Image
              src={'/assets/icons/404-graphic.svg'}
              width={575}
              height={382}
              alt={`404 - ${t('title')}`}
            />

            <Typography variant="bodyMedium" fontWeight={400} color="inactiveLanguageTab">
              {t('title')}
            </Typography>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
